import React from "react"
import { Link } from "gatsby"
import "../styles/index.scss"
import BackgroundSection from "../components/backgroundSection.js"
import Head from "../components/head.js"

const Home = () => {
  return (
    <>
      <BackgroundSection imgsrc={"homepagebg.jpg"} className="home-bg">
        <Head />
        <section className="home">
          <h1 className="home__title">Welcome</h1>
          <div className="categories">
            <Link to="/about" className="categories__link">
              <h1 className="categories__title">
                About <br /> Richard
              </h1>
            </Link>
            <Link to="/realEstate" className="categories__link">
              <h1 className="categories__title">
                Real <br />
                Estate
              </h1>
            </Link>
            <Link to="/diorite" className="categories__link">
              <h1 className="categories__title">
                Diorite <br />
                Contracting
              </h1>
            </Link>
          </div>
        </section>
      </BackgroundSection>
    </>
  )
}
export default Home
